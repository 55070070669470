import { render, staticRenderFns } from "./PropertyTableActions.vue?vue&type=template&id=5b1dd694&scoped=true&"
import script from "./PropertyTableActions.vue?vue&type=script&lang=js&"
export * from "./PropertyTableActions.vue?vue&type=script&lang=js&"
import style0 from "./PropertyTableActions.vue?vue&type=style&index=0&id=5b1dd694&lang=scss&scoped=true&"
import style1 from "./PropertyTableActions.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1dd694",
  null
  
)

export default component.exports