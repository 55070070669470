<template>
  <div class="property-table-actions">
    <div class="selected-info">
      {{ properties.length }} properties of {{ total }} selected
    </div>
    <div>
      <el-select
        v-if="properties.length"
        v-model="action"
        size="small"
        placeholder="Action"
        clearable
      >
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
      <el-button
        v-if="properties.length && action"
        :loading="loading"
        type="success"
        size="small"
        @click="triggerAction"
      >
        Go
      </el-button>
    </div>

    <form-dialog
      :show="showBatchUpdateDialog"
      title="Batch Update Properties"
      width="680px"
    >
      <property-batch-update-form
        :properties="properties"
        :loading="loading"
        @close="showBatchUpdateDialog = false"
        @submit="handleBatchUpdate"
      />
    </form-dialog>

    <batch-operation
      ref="batchUpdate"
      :items="properties"
      :operation="updateProperty"
      :callback="refresh"
      data-type="requests"
      value-key="property.name"
      progress-message="Updating properties..."
    />

    <form-dialog
      :show="showBatchAddSubscriptionDialog"
      title="Batch Add Subscription"
      width="680px"
    >
      <add-subscription-form
        :properties="propertiesToAddSubscription"
        @close="showBatchAddSubscriptionDialog = false"
        @submit="handleBatchAddSubscription"
      />
    </form-dialog>

    <batch-operation
      ref="batchAddSubscription"
      :items="propertiesToAddSubscription"
      :operation="addPropertySubscription"
      :callback="refresh"
      data-type="properties"
      value-key="property.name"
      progress-message="Adding subscriptions..."
    />

    <form-dialog
      :show="batchEdit.showForm"
      title="Batch Edit Subscription"
      width="680px"
    >
      <edit-subscription-form
        :properties="batchEdit.properties"
        @close="batchEdit.showForm = false"
        @submit="handleBatchEditSubscription"
      />
    </form-dialog>
    <global-progress
      :visible="batchEdit.showProgress"
      :percentage="batchEdit.progress"
      message="Updating subscriptions..."
    />
    <batch-results-dialog
      :show="batchEdit.showResults"
      :successes="batchEdit.successes"
      :failures="batchEdit.failures"
      title="Batch Edit Results"
      @close="batchEdit.showResults = false"
    />

    <form-dialog
      :show="batchCancel.showForm"
      title="Batch Cancel Subscription"
      width="600px"
    >
      <cancel-subscription-form
        :properties="batchCancel.properties"
        @close="batchCancel.showForm = false"
        @submit="handleBatchCancelSubscription"
      />
    </form-dialog>
    <global-progress
      :visible="batchCancel.showProgress"
      :percentage="batchCancel.progress"
      message="Cancelling subscriptions..."
    />
    <batch-results-dialog
      :show="batchCancel.showResults"
      :successes="batchCancel.successes"
      :failures="batchCancel.failures"
      title="Batch Cancel Results"
      @close="batchCancel.showResults = false"
    />

    <feature-invites-drawer
      :visible.sync="invites.show"
      :company="company"
      :properties="invites.properties"
      :feature="invites.feature"
      :for-desktop="invites.forDesktop"
      @close="invites.show = false"
    />
  </div>
</template>

<script>
import set from 'lodash.set'
import { enums } from '@/utils/constants'
import { getFeatureSet, getTotalValue } from '@/utils/subscriptions'

import CraigslistAPI from '@/services/api/craigslist'
import RooofAPI from '@/services/api/rooof'

import FormDialog from '@/components/dialogs/FormDialog'
import PropertyBatchUpdateForm from './forms/PropertyBatchUpdateForm'
import BatchOperation from '@/components/batch/BatchOperation'
import BatchAddSubscriptionForm from './forms/BatchAddSubscriptionForm'
import BatchCancelSubscriptionForm from './forms/BatchCancelSubscriptionForm'
import BatchEditSubscriptionForm from './forms/BatchEditSubscriptionForm'
import GlobalProgress from '@/components/progress/GlobalProgress'
import BatchResultsDialog from './dialogs/BatchResultsDialog'
import FeatureInvitesDrawer from './FeatureInvitesDrawer'

export default {
  name: 'PropertyTableActions',
  components: {
    'form-dialog': FormDialog,
    'property-batch-update-form': PropertyBatchUpdateForm,
    'batch-operation': BatchOperation,
    'add-subscription-form': BatchAddSubscriptionForm,
    'edit-subscription-form': BatchEditSubscriptionForm,
    'cancel-subscription-form': BatchCancelSubscriptionForm,
    'global-progress': GlobalProgress,
    'batch-results-dialog': BatchResultsDialog,
    'feature-invites-drawer': FeatureInvitesDrawer
  },
  props: {
    properties: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: [
        { value: 'update', label: 'Edit property data' },
        { value: 'add_subscription', label: 'Add a subscription' },
        { value: 'edit_subscription', label: 'Edit a subscription' },
        { value: 'cancel_subscription', label: 'Cancel a subscription' },
        { value: 'pe4d_invite', label: 'Send PostEngine for Desktop invite' },
        { value: 'pe4c_invite', label: 'Send PostEngine for Chrome invite' },
        { value: 'cl_service_invite', label: 'Send Craigslist Full Service invite' }
      ],
      action: null,
      loading: false,
      showBatchUpdateDialog: false,
      showBatchAddSubscriptionDialog: false,
      propertiesToAddSubscription: [],
      batchCancel: {
        showForm: false,
        properties: [],
        showProgress: false,
        progress: 0,
        showResults: false,
        successes: [],
        failures: []
      },
      batchEdit: {
        showForm: false,
        properties: [],
        showProgress: false,
        progress: 0,
        showResults: false,
        successes: [],
        failures: []
      },
      invites: {
        properties: [],
        feature: '',
        forDesktop: false,
        show: false
      }
    }
  },
  created () {
    this.getTotalValue = getTotalValue
  },
  methods: {
    /**
     * Handler for action menu button trigger.
     */
    async triggerAction () {
      switch (this.action) {
        case 'update':
          this.showBatchUpdateDialog = true
          break
        case 'add_subscription':
          this.propertiesToAddSubscription = await this.fetchSelectedProperties()
          this.showBatchAddSubscriptionDialog = true
          break
        case 'edit_subscription':
          this.batchEdit.properties = await this.fetchSelectedProperties()
          this.batchEdit.showForm = true
          break
        case 'cancel_subscription':
          this.batchCancel.properties = await this.fetchSelectedProperties()
          this.batchCancel.showForm = true
          break
        case 'pe4d_invite':
          this.invitePropertiestoFeature(this.properties.map(property => property.id), enums.features.POSTING)
          break
        case 'pe4c_invite':
          this.invitePropertiestoFeature(this.properties.map(property => property.id), enums.features.POSTING, false)
          break
        case 'cl_service_invite':
          this.invitePropertiestoFeature(this.properties.map(property => property.id), enums.features.POSTING_SERVICE)
          break
      }
    },
    /**
     * Handler for batch action dialog submit.
     * Starts the batch process in BatchOperation.vue
     *
     * @param {Array} values - field/value pairs to update
     */
    async handleBatchUpdate (values) {
      this.$refs['batchUpdate'].start(values)
    },
    /**
     * Handler for batch add subscription dialog submit.
     * Starts the batch process in BatchOperation.vue
     *
     * @param {Object} subscription - subscription to add to properties.
     */
    handleBatchAddSubscription (subscription) {
      this.$refs['batchAddSubscription'].startSequential(subscription)
    },
    /**
     * Fetches Craigslist properties from the API
     */
    fetchSelectedProperties () {
      try {
        this.loading = true
        // if all properties are selected, fetch all properties from api
        if (this.properties.length === this.total) {
          const params = { group: this.company.name }
          return CraigslistAPI.properties.list(params)
        }

        const promises = []
        for (const property of this.properties) {
          promises.push(CraigslistAPI.properties.retrieve(property.id))
        }
        return Promise.all(promises)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Handler for batch cancel subscription dialog submit.
     *
     * @param {Object} subscription - subscription to cancel from properties.
     * subscriptionType: 'product',
     * subscription: 'PostEngine Enterprise',
     * end_date: '2021-11-03',
     * reason_for_cancel: 'budget',
     * reason_for_cancel_other: '',
     * notes: ''
     */
    async handleBatchCancelSubscription (subscription) {
      this.batchCancel.successes = []
      this.batchCancel.failures = []
      this.batchCancel.progress = 0
      this.batchCancel.showProgress = true

      const key = subscription.subscriptionType === 'product'
        ? 'product_subscriptions' : 'feature_subscriptions'

      for (let i = 0; i < this.batchCancel.properties.length; i++) {
        const property = this.batchCancel.properties[i]

        // find cancellable subscription from property
        const subscriptionToCancel = property.property[key].find(sub =>
          sub[subscription.subscriptionType] === subscription.subscription &&
          ![enums.status.INACTIVE,
            enums.status.PENDING,
            enums.status.INVALID
          ].includes(sub.status)
        )

        if (subscriptionToCancel) {
          let startDate = subscriptionToCancel.start_date
          if (subscriptionToCancel.status === enums.status.PRE_TRIAL ||
            subscriptionToCancel.status === enums.status.TRIAL) {
            startDate = subscriptionToCancel.trial_start_date
          }
          // check if end date is after start date
          if (subscription.end_date < startDate) {
            this.batchCancel.failures.push({
              property: property,
              reason: 'End date must be after the start date'
            })
          } else {
            try {
              const before = JSON.parse(JSON.stringify(subscriptionToCancel))
              if (subscriptionToCancel.status === enums.status.PRE_TRIAL ||
                  subscriptionToCancel.status === enums.status.TRIAL) {
                subscriptionToCancel.trial_end_date = subscription.end_date
                subscriptionToCancel.start_date = null
                subscriptionToCancel.end_date = null
              } else {
                subscriptionToCancel.end_date = subscription.end_date
              }
              subscriptionToCancel.reason_for_cancel = subscription.reason_for_cancel
              subscriptionToCancel.reason_for_cancel_other = subscription.reason_for_cancel_other
              subscriptionToCancel.notes = subscription.notes

              const payload = { [key]: property.property[key] }
              const response = await RooofAPI.properties.partialUpdate(property.property.id, payload)
              const after = response[key].find(sub => sub.id === before.id)
              this.batchCancel.successes.push({
                property: property,
                before: before,
                after: after
              })
            } catch (err) {
              this.batchCancel.failures.push({
                property: property,
                reason: err.response ? JSON.stringify(err.response.data, null, 2) : null
              })
            }
          }
        } else {
          // Could not find a cancellable subscription
          this.batchCancel.failures.push({
            property: property,
            reason: `Could not find a cancellable subscription to ${subscription.subscription} for this property.`
          })
        }
        this.batchCancel.progress = Math.round(i / this.batchCancel.properties.length * 100)
      }

      this.batchCancel.showProgress = false
      this.batchCancel.showResults = true
      this.refresh()
    },
    /**
     * Handler for batch edit subscription dialog submit.
     *
     * @param {Object} subscription - subscription to edit from properties.
     */
    async handleBatchEditSubscription (subscription) {
      this.batchEdit.successes = []
      this.batchEdit.failures = []
      this.batchEdit.progress = 0
      this.batchEdit.showProgress = true

      const key = subscription.subscriptionType === 'product'
        ? 'product_subscriptions' : 'feature_subscriptions'

      for (let i = 0; i < this.batchEdit.properties.length; i++) {
        const property = this.batchEdit.properties[i]

        // find updatable subscription from property
        const subscriptionToUpdate = property.property[key].filter(sub =>
          sub[subscription.subscriptionType] === subscription.subscription &&
          ![enums.status.INACTIVE,
            enums.status.INVALID
          ].includes(sub.status)
        )

        if (subscriptionToUpdate.length > 1) {
          this.batchEdit.failures.push({
            property: property,
            reason: 'There are multiple subscriptions of the same product/feature'
          })
        } else if (subscriptionToUpdate.length === 1) {
          const subCopy = subscriptionToUpdate[0]
          let endDate = subCopy.end_date
          let startDate = subscription.start_date ? subscription.start_date : subCopy.start_date
          if (subCopy.status === enums.status.PRE_TRIAL ||
            subCopy.status === enums.status.TRIAL) {
            endDate = subscription.trial_end_date ? parseFloat(subscription.trial_end_date) : parseFloat(subCopy.trial_end_date)
            startDate = subscription.trial_start_date ? parseFloat(subscription.trial_start_date) : parseFloat(subCopy.trial_start_date)
          }

          let total = subscription.rate ? parseFloat(subscription.rate) : parseFloat(subCopy.rate)
          const discount = subscription.discount ? parseFloat(subscription.discount) : parseFloat(subCopy.discount)
          const sub = { rate: total, discount: discount, discount_type: subscription.discount_type }
          total = getTotalValue(sub)

          if (endDate && startDate > endDate) {
            this.batchEdit.failures.push({
              property: property,
              reason: 'Start date must be after end date'
            })
          } else if (total < 0) {
            this.batchEdit.failures.push({
              property: property,
              reason: 'Total rate cannot be a negative value'
            })
          } else if (subscription.low_density && !property.property.unit_count) {
            this.batchEdit.failures.push({
              property: property,
              reason: 'Unit count must be set for low density properties'
            })
          } else { // pass validation
            try {
              const before = JSON.parse(JSON.stringify(subCopy))

              // copy new values
              for (const [key, value] of Object.entries(subscription)) {
                if (value && key !== 'subscriptionType' && key !== 'subscription') {
                  subCopy[key] = value
                }
              }
              const payload = { [key]: property.property[key] }
              const response = await RooofAPI.properties.partialUpdate(property.property.id, payload)
              const after = response[key].find(sub => sub.id === before.id)
              this.batchEdit.successes.push({
                property: property,
                before: before,
                after: after
              })
            } catch (err) {
              this.batchEdit.failures.push({
                property: property,
                reason: err.response ? JSON.stringify(err.response.data, null, 2) : null
              })
            }
          }
        } else {
          // Could not find a updatable subscription
          this.batchEdit.failures.push({
            property: property,
            reason: `Could not find an updatable subscription to ${subscription.subscription} for this property.`
          })
        }
        this.batchEdit.progress = Math.round(i / this.batchEdit.properties.length * 100)
      }

      this.batchEdit.showProgress = false
      this.batchEdit.showResults = true
      this.refresh()
    },
    /**
     * Updates a Rooof property with the given field/value pairs
     *
     * @param {Object} property
     * @param {Array} values - e.g. [{ field: 'property.type', value: 'apartment' }]
     */
    updateProperty (property, values) {
      const promises = []
      const data = {
        id: property.id,
        property: {}
      }
      for (const item of values) {
        // Use lodash.set to handle string dot notation property accessor
        set(data, item.field, item.value)
      }
      if (Object.keys(data.property).length > 0) {
        promises.push(RooofAPI.properties.partialUpdate(data.id, data.property))
      }
      if (Object.keys(data).length > 2) {
        promises.push(CraigslistAPI.properties.partialUpdate(data.id, data))
      }
      return promises
    },
    /**
     * Adds a subscrption to a Rooof property
     *
     * @param {Object} property
     * @param {Object} subscription - subscription to add to properties.
     */
    async addPropertySubscription (property, subscription) {
      const payload = {
        id: property.property.id,
        name: property.property.name,
        property: {
          unit_count: property.property.unit_count
        }
      }
      const subType = subscription.type === 'product' ? 'product_subscriptions' : 'feature_subscriptions'
      payload.property[subType] = property.property[subType]
      payload.property[subType].push(subscription.data)
      await RooofAPI.properties.partialUpdate(payload.id, payload.property)
    },
    /**
     * Emits a refresh event to parent component
     * so it can re fetch the property list.
     */
    refresh () {
      this.$emit('refresh')
    },
    /**
     * Fetches and prepares properties for feature invites.
     *
     * @param {Array} propertyIds
     * @param {String} feature
     * @param {Boolean} forDesktop
     */
    async invitePropertiestoFeature (propertyIds, feature, forDesktop = true) {
      try {
        this.loading = true
        const properties = []
        for (const id of propertyIds) {
          const property = await CraigslistAPI.properties.retrieve(id)
          const validator = this.featureInvitesValidator(property, feature, forDesktop)
          if (!validator.success) {
            this.$message({
              message: validator.error,
              type: 'error',
              showClose: true,
              duration: 5000,
              customClass: 'feature-invite-message'
            })
            return
          }
          properties.push(property)
        }
        this.invites.properties = properties
        this.invites.feature = feature
        this.invites.forDesktop = forDesktop
        this.invites.show = true
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Validates a property for a feature invite.
     *
     * @param {Object} property
     * @param {String} feature
     * @param {Boolean} forDesktop
     * @returns {{ success: Boolean, error: String }}
     */
    featureInvitesValidator (property, feature, forDesktop) {
      const featureSet = getFeatureSet(property.property, status => ![enums.status.INACTIVE, enums.status.INVALID].includes(status))
      let success = featureSet.has(feature)
      let error = ''

      // check for active craigslist account
      if (success && (feature === enums.features.POSTING_SERVICE || forDesktop)) {
        success = !!property.active_account
      }

      if (!success) {
        switch (feature) {
          case enums.features.POSTING:
            error = `One or more properties do not meet the criteria for PostEngine for ${forDesktop ? 'Desktop' : 'Chrome'} invites.
                              Properties must have a subscription to ${enums.features.POSTING}${forDesktop ? ' and an active Craigslist account' : ''}.`
            break
          case enums.features.POSTING_SERVICE:
            error = `One or more properties do not meet the criteria for Craigslist Full Service invites.
                    Properties must have a subscription to ${enums.features.POSTING_SERVICE} and an active Craigslist account.`
        }
      }
      return { success, error }
    }
  }
}
</script>

<style lang="scss" scoped>
.property-table-actions {
  display: inherit;
  align-items: inherit;

  .el-select {
    margin-left: 10px;
  }
  .el-button {
    margin-left: 5px;
  }
  .selected-info {
    font-size: 14px;
    color: #808080
  }
}
</style>

<style>
.feature-invite-message.el-message .el-message__content {
  padding-right: 20px;
  line-height: 1.2;
}
</style>
