<template>
  <drawer
    :visible="visible"
    :show-close="false"
    size="calc(100% - 64px)"
    @update:visible="$emit('update:visible', $event)"
  >
    <template #title>
      <div class="drawer-title">
        <h1>
          ({{ company.human_name }}) {{ inviteType }} Invites
        </h1>
      </div>
      <p class="el-drawer__subtitle">
        Please review the details of the following {{ form.invites.length }} properties before sending {{ inviteType }} invites.
      </p>
    </template>

    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      size="small"
      label-width="150px"
      label-position="left"
    >
      <el-card
        v-for="(invite, index) in form.invites"
        :key="invite.property"
        class="invite-card"
      >
        <template #header>
          <link-button
            :to="{ name: 'PropertyEdit', params: { id: invite.property }}"
            target="_blank"
            type="primary"
          >
            {{ invite.name }}
          </link-button>
          <el-button
            type="text"
            icon="el-icon-close"
            @click="removeInvite(invite.property)"
          />
        </template>
        <el-form-item
          label="Send Invite To:"
          :prop="'invites.' + index + '.recipients'"
          :rules="[{ validator: validateRecipients, trigger: 'blur' }]"
        >
          <el-select
            v-model="invite.recipients"
            placeholder="Type to add a recipient"
            multiple
            filterable
            allow-create
            default-first-option
          />
        </el-form-item>
        <el-form-item
          label="CC Invite To:"
          :prop="'invites.' + index + '.cc_recipients'"
          :rules="[{ validator: validateCCRecipients, trigger: 'blur' }]"
        >
          <el-select
            v-model="invite.cc_recipients"
            placeholder="Type to add a CC recipient"
            multiple
            filterable
            allow-create
            default-first-option
          />
        </el-form-item>
        <el-form-item label="Extra Message:">
          <el-input v-model="invite.instructions" type="textarea" />
        </el-form-item>
        <el-col
          :sm="24"
          :md="12"
        >
          <el-form-item
            label="Craigslist Account:"
            :class="{ 'not-configured' : !invite.craigslist_account || !invite.craigslist_account.email }"
          >
            {{ (invite.craigslist_account && invite.craigslist_account.email) || '(No Active Account)' }}
          </el-form-item>
          <el-form-item
            label="CL Password:"
            :class="{ 'not-configured' : !invite.craigslist_account || !invite.craigslist_account.password }"
          >
            {{ (invite.craigslist_account && invite.craigslist_account.password) || '(Not Configured)' }}
          </el-form-item>
        </el-col>
        <el-col
          :sm="24"
          :md="12"
        >
          <el-form-item label="Fwd Leads To:" :class="{ 'not-configured' : !invite.email_delivery_lead }">
            {{ invite.email_delivery_lead || '(Not Configured)' }}
          </el-form-item>
          <el-form-item label="Fwd Robot Emails To:" :class="{ 'not-configured' : !invite.email_delivery_property }">
            {{ invite.email_delivery_property || '(Not Configured)' }}
          </el-form-item>
        </el-col>
      </el-card>
    </el-form>

    <batch-operation
      ref="batchInvites"
      :items="form.invites"
      :operation="sendInvite"
      :callback="() => $emit('close')"
      data-type="invites"
      value-key="name"
      progress-message="Sending invites..."
    />

    <div class="drawer__footer">
      <el-button @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="submitForm"
      >
        Send Invites
      </el-button>
    </div>
  </drawer>
</template>

<script>
import Drawer from '@/components/drawers/Drawer'
import regex from '@/utils/regex'
import CraigslistAPI from '@/services/api/craigslist'
import BatchOperation from '@/components/batch/BatchOperation'
import LinkButton from '@/components/buttons/LinkButton'
import { enums } from '@/utils/constants'

export default {
  name: 'FeatureInvitesDrawer',
  components: {
    'drawer': Drawer,
    'batch-operation': BatchOperation,
    'link-button': LinkButton
  },
  props: {
    company: {
      required: true,
      type: Object
    },
    properties: {
      required: true,
      type: Array
    },
    feature: {
      required: true,
      type: String
    },
    forDesktop: {
      required: true,
      type: Boolean
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      form: {
        invites: []
      },
      loading: false
    }
  },
  computed: {
    inviteType () {
      switch (this.feature) {
        case enums.features.POSTING:
          return `PostEngine for ${this.forDesktop ? 'Desktop' : 'Chrome'}`
        case enums.features.POSTING_SERVICE:
          return 'PostEngine Full Service for Craigslist'
        default:
          return ''
      }
    }
  },
  watch: {
    properties (properties) {
      this.generateForm(properties)
    }
  },
  methods: {
    /**
     * Generates the form fields based on given properties.
     *
     * @param {Array} properties
     */
    async generateForm (properties) {
      this.form.invites = []
      this.loading = true
      for (const property of properties) {
        const recipients = property.property.email_contact ? [property.property.email_contact] : []
        this.form.invites.push({
          property: property.property.id,
          name: property.property.name,
          recipients: recipients,
          cc_recipients: [],
          instructions: '',
          feature: this.feature,
          for_postengine_desktop: this.forDesktop,
          email_delivery_lead: property.property.email_delivery_lead,
          email_delivery_property: property.property.email_delivery_property,
          craigslist_account: await this.fetchActiveCraigslistAccount(property)
        })
      }
      this.loading = false
    },
    /**
     * Fetches the first active Craigslist account for a given property.
     *
     * @param {Object} property
     * @returns {Object} - craigslist account
     */
    async fetchActiveCraigslistAccount (property) {
      if (!property.active_account) {
        return null
      }
      try {
        const clAccount = await CraigslistAPI.accounts.retrieve(property.active_account)
        return clAccount
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Removes the invite from the list.
     *
     * @param {Number} propertyId
     */
    removeInvite (propertyId) {
      this.form.invites = this.form.invites.filter(invite => invite.property !== propertyId)
      if (!this.form.invites.length) {
        this.$emit('close')
      }
    },
    /**
     * Validator for invite recipients.
     * See custom validation in https://element.eleme.io/#/en-US/component/form
     */
    validateRecipients (rule, value, callback) {
      if (!value.length) {
        return callback(new Error('At least one recipient is required'))
      }
      if (!value.every(email => regex.email.test(email))) {
        return callback(new Error('Please ensure all email addresses are valid'))
      }
      return callback()
    },
    /**
     * Validator for invite CC recipients.
     */
    validateCCRecipients (rule, value, callback) {
      if (!value.every(email => regex.email.test(email))) {
        return callback(new Error('Please ensure all email addresses are valid'))
      }
      return callback()
    },
    /**
     * Validates the form then starts the batch update process.
     */
    submitForm () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$refs['batchInvites'].start()
        }
      })
    },
    /**
     * Sends the feature invite for a property.
     *
     * @param {Object} invite - form fields
     */
    async sendInvite (invite) {
      const payload = {
        property: invite.property,
        recipients: invite.recipients,
        cc_recipients: invite.cc_recipients,
        instructions: invite.instructions,
        feature: invite.feature,
        for_postengine_desktop: invite.for_postengine_desktop
      }
      await CraigslistAPI.feature_invites.create(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-drawer__wrapper {
  ::v-deep .el-drawer  {
    .el-drawer__header {
      h1 {
        margin: auto;
      }
    }
    .el-drawer__body {
      overflow: auto;
      padding-bottom: 80px;

      .invite-card {
        max-width: 1000px;
        margin-bottom: 1em;
        .el-card__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .el-link {
            font-size: 1rem;
          }
          .el-button {
            padding: 0;
            font-size: 1rem;
          }
        }
        .el-card__body {
          .el-select {
            width: 100%;
            .el-select__caret {
              display: none;
            }
          }
          .el-form-item.not-configured {
            color: #f56c6c;
          }
        }
      }

      .drawer__footer {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        padding: 1em;
        width: 100%;
        text-align: right;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12),
                      0 0 6px rgba(0, 0, 0, .12);
      }
    }
  }
}
</style>
