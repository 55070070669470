<template>
  <div id="edit-subscription-form">
    <el-alert
      title="PROCEED WITH CAUTION. This will update all subscriptions that are not inactive."
      type="warning"
      description="Review subscription details (start date, price, discount) before updating."
      show-icon
      :closable="false"
    />
    <div class="form-tip">
      This will update a subscription to <b>{{ properties.length }}</b> properties.
    </div>

    <el-form
      ref="edit-form"
      :model="form"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item
        label="Type"
        class="is-required"
        prop="subscriptionType"
      >
        <el-radio v-model="form.subscriptionType" label="product">
          Product
        </el-radio>
        <el-radio v-model="form.subscriptionType" label="feature">
          Feature
        </el-radio>
      </el-form-item>

      <el-form-item
        :label="subscriptionTypeLabel"
        prop="subscription"
        class="is-required"
      >
        <el-select
          v-model="form.subscription"
        >
          <el-option
            v-for="option in subscriptionOptions"
            :key="option.id"
            :label="option.name"
            :value="option.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Free Trial">
        <div class="row">
          <el-form-item prop="trial_start_date">
            <el-date-picker
              v-model="form.trial_start_date"
              type="date"
              placeholder="Choose start"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <div class="line">
            -
          </div>
          <el-form-item prop="trial_end_date">
            <el-date-picker
              v-model="form.trial_end_date"
              :picker-options="getPickerOptions(form.trial_start_date)"
              type="date"
              placeholder="Choose end"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Billing Dates">
        <div class="row">
          <el-form-item prop="start_date">
            <el-date-picker
              v-model="form.start_date"
              :picker-options="getPickerOptions(form.trial_end_date)"
              type="date"
              placeholder="Choose start"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Monthly Rate">
        <div class="row short">
          <el-form-item prop="rate">
            <el-input
              v-model="form.rate"
              placeholder="0.00"
              class="small-input"
            >
              <template slot="prepend">
                $ {{ currencyCode }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Set Up Fee">
        <div class="row short">
          <el-form-item prop="setup_fee">
            <el-input
              v-model="form.setup_fee"
              placeholder="-"
              class="small-input"
            >
              <template slot="prepend">
                $ {{ currencyCode }}
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="Discount">
        <div class="row">
          <el-form-item prop="discount">
            <el-input
              v-model="form.discount"
              placeholder="0.00"
              class="small-input"
            >
              <template v-if="form.discount_type === '$'" :slot="'prepend'">
                {{ form.discount_type }} {{ currencyCode }}
              </template>
              <template v-else :slot="'append'">
                {{ form.discount_type }}
              </template>
            </el-input>
          </el-form-item>
          <el-select v-model="form.discount_type" class="col small-input">
            <el-option label="Dollars" value="$" />
            <el-option label="Percent" value="%" />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="Billing Frequency">
        <div class="row">
          <el-form-item>
            <el-select v-model="form.billing_frequency">
              <el-option
                v-for="option in billingFrequencyOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item
        label="Low Density"
        prop="low_density"
      >
        <el-switch v-model="form.low_density" />
      </el-form-item>
      <div class="form-controls">
        <el-button @click="cancel">
          Cancel
        </el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { constants } from '@/utils/constants'
import { validators } from '@/utils/element'

export default {
  name: 'BatchEditSubscriptionForm',
  props: {
    properties: {
      type: Array,
      required: true
    }
  },
  data () {
    // Custom validator to check for product/feature selection
    const subscriptionTypeValidator = (rule, value, callback) => {
      if (!this.form.subscriptionType) {
        return callback(new Error('This field is required'))
      }
      return callback()
    }
    // Custom validator for discount
    const discountValidator = (rule, value, callback) => {
      if (!value) {
        return callback()
      }

      const rate = parseFloat(this.form.rate)
      const discount = parseFloat(value)

      if (this.form.discount_type === '$' && discount > rate) {
        return callback(new Error('Cannot exceed rate'))
      } else if (this.form.discount_type === '%' && discount > 100) {
        return callback(new Error('Value cannot exceed 100'))
      }
      return callback()
    }
    return {
      form: {
        subscriptionType: 'product',
        subscription: null,
        trial_start_date: '',
        trial_end_date: '',
        start_date: '',
        rate: '',
        setup_fee: '',
        discount: '',
        discount_type: '',
        billing_frequency: '',
        low_density: false
      },
      rules: {
        subscription: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        subscriptionType: [
          { validator: subscriptionTypeValidator, trigger: 'blur' }
        ],
        rate: [
          { validator: validators.positiveNumber, trigger: 'blur' }
        ],
        setup_fee: [
          { validator: validators.positiveNumber, trigger: 'blur' }
        ],
        discount: [
          { validator: validators.positiveNumber, trigger: 'blur' },
          { validator: discountValidator, trigger: 'change' }
        ],
        trial_start_date: [
          { validator: validators.date, trigger: 'blur' }
        ],
        trial_end_date: [
          { validator: validators.date, trigger: 'blur' }
        ],
        start_date: [
          { validator: validators.date, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    currencyCode () {
      return this.$store.getters.getCompanyById(this.$route.params.cid).currency_code
    },
    subscriptionTypeLabel () {
      return this.form.subscriptionType.charAt(0).toUpperCase() + this.form.subscriptionType.slice(1)
    },
    ...mapState({
      'products': state => state.products,
      'features': state => state.features
    }),
    subscriptionOptions () {
      switch (this.form.subscriptionType) {
        case 'product':
          return this.products
        case 'feature':
          return this.features
        default:
          return []
      }
    }
  },
  created () {
    this.billingFrequencyOptions = constants.billingFrequency.filter(el => {
      return el.value !== null // remove 'Unknown'
    })
    this.constants = constants
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['edit-form'].validate(valid => {
        if (!valid) {
          return
        }
        this.$emit('submit', JSON.parse(JSON.stringify(this.form)))
        this.$emit('close')
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'close' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['edit-form'].resetFields()
    },
    /**
     * Create the object which can be passed into
     * element-ui's datepicker `pickerOptions` prop.
     *
     * Disables all dates up to and including the
     * provided date.
     *
     * @param {String|null} value - disable up to (and including) this date
     * @returns {Object}
     */
    getPickerOptions (value) {
      value = value !== null ? this.$moment(value).endOf('day') : value
      return {
        disabledDate: date => {
          return date <= value
        }
      }
    }
  }
}
</script>

<style scoped>
#edit-subscription-form .el-input-group__prepend {
  padding: 0 10px;
}
#edit-subscription-form .el-input-group__append {
  padding: 0 10px;
}
.row {
  display: flex;
}
.row.short {
  width: 480px;
}
.row.el-date-picker {
  width: 100px;
}
.small-input {
  min-width: 150px;
  max-width: 150px;
}
.col {
  margin-left: 20px;
}
.line {
  padding: 0 20px;
}
.form-controls {
  display: flex;
  justify-content: flex-end;
}
.form-tip {
  word-break: normal;
  padding-bottom: 20px;
  padding-top: 20px;
}
</style>
