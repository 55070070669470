import { render, staticRenderFns } from "./FeatureInvitesDrawer.vue?vue&type=template&id=66b9fceb&scoped=true&"
import script from "./FeatureInvitesDrawer.vue?vue&type=script&lang=js&"
export * from "./FeatureInvitesDrawer.vue?vue&type=script&lang=js&"
import style0 from "./FeatureInvitesDrawer.vue?vue&type=style&index=0&id=66b9fceb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b9fceb",
  null
  
)

export default component.exports