<template>
  <div>
    <div class="form-tip">
      This will add a subscription to <b>{{ properties.length }}</b> properties.
      Which subscription do you want to add?
    </div>

    <el-form label-width="140px">
      <el-form-item label="Type" class="is-required">
        <el-radio v-model="subscriptionType" label="product">
          Product
        </el-radio>
        <el-radio v-model="subscriptionType" label="feature">
          Feature
        </el-radio>
      </el-form-item>
    </el-form>

    <subscription-form
      :subscription-type="subscriptionType"
      :unit-count="unitCount"
      :show-end-date="true"
      @close="$emit('close')"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import SubscriptionForm from '@/views/posting-groups/properties/property-form/_components/forms/subscription/SubscriptionForm'

export default {
  name: 'BatchAddSubscriptionForm',
  components: {
    'subscription-form': SubscriptionForm
  },
  props: {
    properties: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      subscriptionType: 'product'
    }
  },
  computed: {
    /**
     * Checks each property for if they have unit count.
     * If all of them do, return 1. If not, return 0.
     */
    unitCount () {
      for (const property of this.properties) {
        if (!property.property.unit_count) {
          return 0
        }
      }
      return 1
    }
  },
  methods: {
    handleSubmit (formData) {
      const subscription = {
        type: this.subscriptionType,
        data: formData
      }

      this.$emit('submit', subscription)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.form-tip {
  word-break: normal;
  padding-bottom: 20px;
}
</style>
