<template>
  <div>
    <el-row
      type="flex"
      align="middle"
      justify="space-between"
    >
      <property-table-actions
        :properties="selected"
        :total="total"
        :company="company"
        @refresh="fetchData"
      />
      <div>
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="medium"
          @click="$router.push({ name: 'PropertyAdd' })"
        >
          Add Property
        </el-button>
        <el-button
          size="medium"
          :loading="exporting"
          @click="exportProperties"
        >
          Export
        </el-button>
        <el-button
          size="medium"
          @click="$router.push({ name: 'PropertyBatchUpload' })"
        >
          Import
        </el-button>
      </div>
    </el-row>
    <el-row class="search">
      <el-input
        v-model="searchValue"
        placeholder="Search"
        size="small"
      />
    </el-row>
    <data-table
      :data="filteredData"
      :loading="loading"
      :selected="selected"
      height="calc(100vh - 354px)"
      @selection-change="selected = $event"
    >
      <el-table-column
        type="selection"
        width="40"
      />
      <el-table-column
        label="Name"
        prop="name"
        min-width="200"
        sortable
      >
        <template slot-scope="scope">
          <link-button
            type="primary"
            :to="{ name: 'PropertyEdit', params: { id: scope.row.id } }"
          >
            {{ scope.row.name }}
          </link-button>
        </template>
      </el-table-column>
      <el-table-column
        label="Activation Code"
        prop="activation_code"
        width="230"
      />
      <el-table-column
        label="Status"
        prop="status"
        width="115"
        sortable
      >
        <template slot-scope="scope">
          <el-tag
            :type="getStatusTagType(scope.row.status)"
            size="medium"
            disable-transitions
          >
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Products"
        prop="active_products"
        width="200"
      >
        <template slot-scope="scope">
          <el-row type="flex">
            <product-tag
              v-for="product in sortProducts(scope.row.active_products)"
              :key="product"
              :product="product"
              size="medium"
            />
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        label="Billing Start Date"
        prop="active_start_date"
        width="130"
      />
      <el-table-column
        label="Property Email"
        prop="email"
        width="300"
        sortable
      />
      <el-table-column
        label="Phone"
        prop="phone"
        width="190"
        sortable
      />
      <el-table-column
        label="Monthly Billed"
        prop="rate"
        width="120"
      />
    </data-table>

    <data-export-dialog
      :show="showExportDialog"
      :data="propertiesToExport"
      data-type="properties"
      @close="showExportDialog = false"
    />
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import DataExportDialog from '@/components/dialogs/DataExportDialog'
import PropertyTableActions from './PropertyTableActions'
import LinkButton from '@/components/buttons/LinkButton'
import ProductTag from '@/components/tags/ProductTag'
import { enums } from '@/utils/constants'
import { getStatusTagType } from '@/utils/rooof'

import CraigslistAPI from '@/services/api/craigslist'
import RooofAPI from '@/services/api/rooof'

// Comparator for status
const STATUS_ORDERING = [
  'active', 'onboarding', 'pre-trial', 'trial', 'pending', 'offboarding', 'auto-created', 'inactive', 'invalid'
]
const statusSort = (a, b) => {
  return STATUS_ORDERING.indexOf(a.status) - STATUS_ORDERING.indexOf(b.status)
}

export default {
  name: 'CompanyOverviewPropertyTable',
  components: {
    'data-table': DataTable,
    'data-export-dialog': DataExportDialog,
    'property-table-actions': PropertyTableActions,
    'link-button': LinkButton,
    'product-tag': ProductTag
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: [],
      searchValue: '',
      total: 0,
      loading: false,
      selected: [],
      showExportDialog: false,
      propertiesToExport: [],
      exporting: false
    }
  },
  computed: {
    filteredData () {
      const search = this.searchValue.toLowerCase()
      return this.data.filter(property => property.name.toLowerCase().includes(search) ||
        property.email.includes(search) || property.phone.includes(search)
      )
    }
  },
  created () {
    this.getStatusTagType = getStatusTagType
    this.fetchData()
  },
  methods: {
    /**
     * Fetch list of properties from the api.
     */
    async fetchData () {
      this.loading = true
      try {
        const params = { group: this.$route.params.cid }
        const properties = RooofAPI.companies.propertySummary(this.company.id, params)
        const rates = RooofAPI.companies.billingRates({ company: this.company.id })
        const response = await Promise.all([properties, rates])
        this.data = this.formatPropertySummary(response)
        this.total = this.data.length
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.loading = false
    },
    /**
     * Format API response to match property with monthly billing rates
     * @param response - response from two api calls
     */
    formatPropertySummary (response) {
      const propertySummary = response[0].sort(statusSort)
      response = propertySummary.map(prop => ({ ...prop, ...response[1].find(rate => rate.id === prop.id) }))
      response.forEach(property => {
        if (property.status !== enums.status.ACTIVE) {
          property.rate = 'N/A'
        }
      })
      return response
    },
    /**
     * Fetch properties from api and open export dialog
     */
    async exportProperties () {
      this.exporting = true
      try {
        const params = { group: this.company.name }
        const response = await CraigslistAPI.properties.list(params)
        this.propertiesToExport = response.sort(statusSort)
        this.showExportDialog = true
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.exporting = false
    },
    /**
     * Sort function for active products. Craigslist products first, then alphabetical.
     * @param {Array} products
     * @returns {Array} sorted products
     */
    sortProducts (products) {
      const productsCopy = JSON.parse(JSON.stringify(products))
      productsCopy.sort((a, b) => {
        if (a.toLowerCase().includes('craigslist') && !b.toLowerCase().includes('craigslist')) {
          return -1
        }
        if (b.toLowerCase().includes('craigslist') && !a.toLowerCase().includes('craigslist')) {
          return 1
        }
      })
      return productsCopy
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  margin: 1em 0;
  .el-input {
    max-width: 300px;
  }
}
</style>
