import ListInput from '@/components/forms/ListInput'
import { constants } from '@/utils/constants'
import { validators } from '@/utils/element'

// Common rule formats
const rules = {
  email: {
    type: 'email',
    message: 'Please provide a valid email address',
    trigger: 'blur'
  },
  phone: {
    max: 31,
    message: 'Phone number cannot be more than 31 characters',
    trigger: 'blur'
  },
  customTrackingParameter: {
    validator: validators.trackingParameterValidator,
    message: 'Tracking parameters are invalid, must begin with either "/" or "?"',
    trigger: 'blur'
  }
}

// Format list of items as el-option components
const getSelectOptions = (values, labelKey = 'label', valueKey = 'value') => {
  return values.filter(el => el[valueKey] !== null).map(el => {
    return {
      component: 'el-option',
      options: {
        props: { label: el[labelKey], value: el[valueKey] }
      }
    }
  })
}

/**
 * Property batch update field schema definition.
 *
 * Defines the list of fields which are updatable
 * using the MultiFieldUpdate component.
 *
 * The calling component should pass its context
 * object so that we can use it for validation.
 *
 * @param {Object} vm - component instance
 * @param {Array} vm.data - field/value pairs
 * @returns {Object}
 */
export default function fieldConfig (vm) {
  return [
    {
      label: 'Type',
      value: 'property.type',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.propertyTypes)
      }
    },
    {
      label: 'Property Email',
      value: 'property.email_contact',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'Lead Email',
      value: 'property.email_leasing',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'Manager Email',
      value: 'property.email_manager',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'Billing Email',
      value: 'property.billing_email',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'Full Service Contact Email',
      value: 'property.email_agency',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'Full Service DM Email',
      value: 'property.email_agency_decisionmaker',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Property Phone',
      value: 'property.phone_contact',
      render: {
        component: 'el-input'
      },
      rules: rules.phone
    },
    {
      label: 'Lead Phone',
      value: 'property.phone_leasing',
      render: {
        component: 'el-input'
      },
      rules: rules.phone
    },
    {
      label: 'CallRail Phone',
      value: 'property.phone_callrail',
      render: {
        component: 'el-input'
      },
      rules: rules.phone
    },
    {
      label: 'EmailHandler - Delivery Lead',
      value: 'property.email_delivery_lead',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'EmailHandler - Delivery Lead Copy',
      value: 'property.email_delivery_lead_copy',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'EmailHandler - Delivery Lead Property',
      value: 'property.email_delivery_property',
      render: {
        component: 'el-input'
      },
      rules: rules.email
    },
    {
      label: 'Posting Day',
      value: 'property.posting_day',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.postingDayOptions)
      }
    },
    {
      label: 'Custom Tracking Parameters',
      value: 'property.custom_tracking_parameters',
      render: {
        component: 'el-input'
      },
      rules: rules.customTrackingParameter
    },
    {
      label: 'Posting Category',
      value: 'posting_category',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.postingCategory)
      }
    },
    {
      label: 'Rent Period',
      value: 'rent_period',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.rentPeriod)
      }
    },
    {
      label: 'Major Region',
      value: 'major_region',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(vm.$store.state.regions.major_regions, 'name', 'id')
      }
    },
    {
      label: 'Sub Region',
      value: 'sub_region',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(vm.$store.state.regions.sub_regions, 'name', 'id')
      }
    },
    {
      label: 'Sub-sub Region',
      value: 'sub_sub_region',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(vm.$store.state.regions.sub_sub_regions, 'name', 'id')
      }
    },
    // {
    //   label: 'Phone Number',
    //   value: 'phone_number',
    //   render: {
    //     component: 'el-input'
    //   },
    //   rules: rules.phone
    // },
    // {
    //   label: 'Phone Extension',
    //   value: 'phone_extension',
    //   render: {
    //     component: 'el-input'
    //   }
    // },
    // {
    //   label: 'Accept Text Messages',
    //   value: 'contact_by_text',
    //   render: {
    //     component: 'el-select',
    //     options: {
    //       props: {
    //         filterable: true
    //       }
    //     },
    //     children: getSelectOptions(constants.contactBy)
    //   }
    // },
    // {
    //   label: 'Accept Phone Calls',
    //   value: 'contact_by_phone',
    //   render: {
    //     component: 'el-select',
    //     options: {
    //       props: {
    //         filterable: true
    //       }
    //     },
    //     children: getSelectOptions(constants.contactBy)
    //   }
    // },
    // {
    //   label: 'Contact Name',
    //   value: 'contact_name',
    //   render: {
    //     component: 'el-input'
    //   }
    // },
    {
      label: 'Email Policy',
      value: 'email_policy',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.emailPolicy)
      }
    },
    {
      label: 'Dog Policy',
      value: 'dog_policy',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.dogPolicy)
      }
    },
    {
      label: 'Cat Policy',
      value: 'cat_policy',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.catPolicy)
      }
    },
    {
      label: 'Smoking Policy',
      value: 'smoking_policy',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.smokingPolicy)
      }
    },
    {
      label: 'Housing Type',
      value: 'housing_type',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.housingTypes)
      }
    },
    {
      label: 'Wheelchair Access',
      value: 'wheelchair_access',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.wheelchairAccess)
      }
    },
    {
      label: 'Furnished',
      value: 'furnished',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.furnished)
      }
    },
    {
      label: 'Laundry',
      value: 'laundry',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.laundry)
      }
    },
    {
      label: 'Parking',
      value: 'parking',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.parking)
      }
    },
    {
      label: 'Air Conditioning',
      value: 'air_conditioning',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.airConditioning)
      }
    },
    {
      label: 'EV Charging',
      value: 'ev_charging',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.evCharging)
      }
    },
    {
      label: 'Location(s)',
      value: 'location',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Amenities (property)',
      value: 'amenities_property',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Amenities (unit)',
      value: 'amenities_unit',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Title Behavior',
      value: 'title_setting',
      render: {
        component: 'el-select',
        options: {
          props: {
            filterable: true
          }
        },
        children: getSelectOptions(constants.titleSetting)
      }
    },
    {
      label: 'Title',
      value: 'title',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Romance Text',
      value: 'romance_text',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Disclaimer(s)',
      value: 'disclaimer',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Header(s)',
      value: 'header',
      type: Array,
      render: {
        component: ListInput
      }
    },
    {
      label: 'Footer(s)',
      value: 'footer',
      type: Array,
      render: {
        component: ListInput
      }
    }
  ]
}
