<template>
  <el-tag
    :size="size"
    class="product-tag"
    :class="tagClasses"
    type="info"
  >
    <peace-symbol-icon v-if="isCraigslistProduct" />
    <google-icon v-if="isGoogleProduct" />
    <span class="label">{{ tagLabel }}</span>
  </el-tag>
</template>

<script>
import PeaceSymbolIcon from '@/assets/icons/svg/peace_symbol.svg'
import GoogleIcon from '@/assets/icons/svg/google.svg'

export default {
  name: 'ProductTag',
  components: {
    'peace-symbol-icon': PeaceSymbolIcon,
    'google-icon': GoogleIcon
  },
  props: {
    product: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    tagLabel () {
      const productName = this.product.toLowerCase()
      if (productName.includes('premium')) {
        return 'Premium'
      }
      if (productName.includes('service')) {
        return 'Service'
      }
      if (productName.includes('software')) {
        return 'Software'
      }
      if (productName.includes('agency')) {
        return 'Agency'
      }
      if (productName.includes('central')) {
        return 'Central'
      }
      return this.product
    },
    isCraigslistProduct () {
      return this.product.toLowerCase().includes('craigslist')
    },
    isGoogleProduct () {
      return this.product.toLowerCase().includes('google')
    },
    tagClasses () {
      return {
        'product-tag--craigslist': this.isCraigslistProduct,
        'product-tag--google': this.isGoogleProduct,
        'product-tag--service': this.product.toLowerCase().includes('service'),
        'product-tag--premium': this.product.toLowerCase().includes('premium'),
        'product-tag--agency': this.product.toLowerCase().includes('agency'),
        'product-tag--central': this.product.toLowerCase().includes('central')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$craiglist-purple: #551A8B;
$craigslist-blue: #0000EE;
$premium: #FAD161;
$google-blue: #4285F4;
$google-red: #DB4437;
$google-yellow: #F4B400;
$google-green: #0F9D58;

.product-tag {
  display: flex;
  width: fit-content;
  align-items: center;

  +.product-tag {
    margin-left: 5px;
  }
  &.product-tag--craigslist {
    color: $craiglist-purple;
    border-color: rgba($craiglist-purple, 30%);
    background-color: rgba($craiglist-purple, 10%);

    svg {
      fill: $craiglist-purple;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &.product-tag--service:not(.product-tag--premium) {
      color: $craigslist-blue;
      border-color: rgba($craigslist-blue, 30%);
      background-color: rgba($craigslist-blue, 10%);
      svg {
        fill: $craigslist-blue;
      }
    }

    &.product-tag--premium {
      border-color: $premium;
      background-color: rgba($premium, 30%);
    }

    &.product-tag--central {
      background-color: #f4f4f5;
      border-color: #c0c4cc;
    }
  }
  &.product-tag--google {
    color: $google-blue;
    border-color: rgba($google-blue, 40%);
    background-color: rgba($google-blue, 10%);

    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-left: -2px;
    }

    &.product-tag--agency {
      background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, $google-blue , $google-red, $google-yellow, $google-green) border-box;
      border-color: transparent;

      .label {
        color: #303133; // $--color-text-primary
      }
    }
  }
}
</style>
