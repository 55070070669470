<template>
  <div>
    <div class="form-tip">
      This will cancel a subscription to <b>{{ properties.length }}</b> properties.
      Which subscription do you want to cancel?
    </div>

    <el-form
      ref="cancel-form"
      :model="form"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item
        label="Type"
        class="is-required"
        prop="subscriptionType"
      >
        <el-radio v-model="form.subscriptionType" label="product">
          Product
        </el-radio>
        <el-radio v-model="form.subscriptionType" label="feature">
          Feature
        </el-radio>
      </el-form-item>

      <el-form-item
        :label="subscriptionTypeLabel"
        class="is-required"
        prop="subscription"
      >
        <el-select
          v-model="form.subscription"
        >
          <el-option
            v-for="option in subscriptionOptions"
            :key="option.id"
            :label="option.name"
            :value="option.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="End Date"
        class="is-required"
        prop="end_date"
      >
        <el-date-picker
          v-model="form.end_date"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="Choose date..."
        />
      </el-form-item>

      <el-form-item
        label="Reason for Cancel"
        class="is-required"
        prop="reason_for_cancel"
      >
        <el-select
          v-model="form.reason_for_cancel"
          filterable
          clearable
        >
          <el-option
            v-for="reason in reasonForCancel"
            :key="reason.value"
            :label="reason.label"
            :value="reason.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="form.reason_for_cancel === 'other'"
        class="is-required"
        label="Please specify"
        prop="reason_for_cancel_other"
      >
        <el-input v-model="form.reason_for_cancel_other" />
      </el-form-item>

      <el-form-item label="Notes" prop="notes">
        <el-input
          v-model="form.notes"
          type="textarea"
          :rows="4"
        />
      </el-form-item>

      <div class="form-controls">
        <el-button @click="cancel">
          Cancel
        </el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { constants } from '@/utils/constants'

export default {
  name: 'BatchCancelSubscriptionForm',
  props: {
    properties: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      form: {
        subscriptionType: 'product',
        subscription: null,
        end_date: '',
        reason_for_cancel: '',
        reason_for_cancel_other: '',
        notes: ''
      },
      rules: {
        subscription: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        end_date: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        reason_for_cancel: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        reason_for_cancel_other: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    subscriptionTypeLabel () {
      return this.form.subscriptionType.charAt(0).toUpperCase() + this.form.subscriptionType.slice(1)
    },
    ...mapState({
      'products': state => state.products,
      'features': state => state.features
    }),
    subscriptionOptions () {
      switch (this.form.subscriptionType) {
        case 'product':
          return this.products
        case 'feature':
          return this.features
        default:
          return []
      }
    }
  },
  created () {
    this.constants = constants
    const toRemove = ['subscription-change', 'switching-to-manual']
    this.reasonForCancel = this.constants.reasonForCancel.filter((el) =>
      !toRemove.includes(el.value)
    )
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['cancel-form'].validate(valid => {
        if (!valid) {
          return
        }
        this.$emit('submit', JSON.parse(JSON.stringify(this.form)))
        this.$emit('close')
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'close' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['cancel-form'].resetFields()
    }
  }
}
</script>

<style scoped>
.form-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 3em;
}
.form-tip {
  word-break: normal;
  padding-bottom: 20px;
}
</style>
