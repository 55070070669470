<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="title"
    width="80%"
    append-to-body
    @close="$emit('close')"
  >
    <h1>Out of {{ total }} properties:</h1>
    <el-alert
      type="success"
      show-icon
      :closable="false"
    >
      <template #title>
        <div class="alert-title" @click="showSuccesses = !showSuccesses">
          <span>{{ successes.length }} were successful</span>
          <i :class="showSuccesses ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" />
        </div>
      </template>
      <el-collapse v-show="showSuccesses">
        <el-collapse-item
          v-for="success in successes"
          :key="success.property.property.id"
          :title="success.property.property.name"
        >
          <el-row
            type="flex"
            justify="space-between"
            class="diff"
          >
            <el-col>
              <h4>Before:</h4>
              <ul class="diff-container">
                <div>{</div>
                <template v-for="(value, property, index) in success.before">
                  <li
                    v-if="property !== 'uid'"
                    :key="`${success.property.property.id}_${index}`"
                    :class="{ 'before-changed': success.after[property] !== value }"
                  >
                    "{{ property }}": {{ formatValue(value, index === Object.keys(success.before).length-1) }}
                  </li>
                </template>
                <div>}</div>
              </ul>
            </el-col>
            <el-col>
              <h4>After:</h4>
              <ul class="diff-container">
                <div>{</div>
                <template v-for="(value, property, index) in success.after">
                  <li
                    v-if="property !== 'uid'"
                    :key="`${success.property.property.id}_${index}`"
                    :class="{ 'after-changed': success.before[property] !== value }"
                  >
                    "{{ property }}": {{ formatValue(value, index === Object.keys(success.after).length-1) }}
                  </li>
                </template>
                <div>}</div>
              </ul>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-alert>
    <el-alert
      type="error"
      show-icon
      :closable="false"
    >
      <template #title>
        <div class="alert-title" @click="showFailures = !showFailures">
          <span>{{ failures.length }} have failed</span>
          <i :class="showFailures ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" />
        </div>
      </template>
      <el-collapse v-show="showFailures">
        <el-collapse-item
          v-for="failure in failures"
          :key="failure.property.property.id"
          :title="failure.property.property.name"
        >
          <pre>{{ failure.reason }}</pre>
        </el-collapse-item>
      </el-collapse>
    </el-alert>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="$emit('close')">
        Close
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'BatchResultsDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    successes: {
      type: Array,
      required: true
    },
    failures: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showSuccesses: false,
      showFailures: false
    }
  },
  computed: {
    total () {
      return this.successes.length + this.failures.length
    }
  },
  methods: {
    formatValue (value, isLastItem) {
      let str = ''
      if (value === null) {
        str = 'null'
      } else if (typeof value === 'string') {
        str = `"${value}"`
      } else {
        str = value
      }

      if (!isLastItem) {
        str += ','
      }
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0;
  h1 {
    font-size: 1rem;
  }
  .el-alert {
    margin-bottom: 1em;
    padding-right: 0;
    align-items: flex-start;
    .el-alert__icon {
      padding-top: 1px;
      width: 16px;
      font-size: 16px;
    }
    .el-alert__content {
      width: 100%;
      .alert-title {
        display: flex;
        align-items: middle;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 400;

        i {
          margin: auto 7px;
        }
      }
      .el-alert__description {
        margin: 0;
        .el-collapse {
          border: none;
          margin-top: 0.25em;
          .el-collapse-item__header {
            background-color: transparent;
            height: 30px;
            border: none;
            font-weight: 400;
            font-size: 12px;
          }
          .el-collapse-item__wrap {
            background-color: transparent;
            border: none;
            .el-collapse-item__content {
              padding: 0;

              pre {
                background-color: #f5f7fa;
                border: 1px solid #dcdfe6;
                padding: 0 4px;
                margin: 0;
                white-space: break-spaces;
              }

              .diff {
                h4 {
                  margin: 0;
                }
                .el-col:first-of-type {
                  margin-right: 1em;
                }
                .el-col:last-of-type {
                  margin-left: 1em;
                }
                .diff-container {
                  background-color: #f5f7fa;
                  border: 1px solid #dcdfe6;
                  padding: 0 4px;
                  margin: 0;
                  white-space: break-spaces;
                  font-family: monospace;
                  li {
                    padding-left: 1em;
                    list-style-type: none;

                    &.before-changed {
                      background-color: #f8b2b2;
                    }

                    &.after-changed {
                      background-color: #c4eeaf;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
